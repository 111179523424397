import { withStyles, TableBody, TableCell, TableHead, TableRow, Box } from '@material-ui/core';
import MaUTable from '@material-ui/core/Table';
import { MouseEventHandler } from 'react';
import { useEffect, useState } from 'react';
import { Column, Row, TableOptions, useTable } from 'react-table';
import IntervalCell from './IntervalCell';

interface EditableCellProps {
  value: number | string;
  row: Row;
  column: Column;
  background: string;
  inputType: boolean;
  updateMyData: Function;
  onEditDist: MouseEventHandler;
}

interface TableProps {
  columns: Column[];
  data: any;
  updateMyData: Function;
  skipPageReset: any;
  onEditDist?: Function;
  getCellProps?: Function;
}

interface TableOptionsProps extends TableOptions<{}> {
  autoResetPage: boolean;
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    background: '#444',
    color: '#fff',
    textAlign: 'center',
  },
  body: {
    fontSize: 14,
    textAlign: 'center',
    padding: 0,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const EditableCell = ({
  value: initialValue,
  row,
  column,
  inputType,
  background,
  updateMyData, // This is a custom function that we supplied to our table instance
  onEditDist,
}: EditableCellProps) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  // console.log({ index, id });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseInt(e.target.value));
  };

  // console.log({ initialValue, column, row });

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    if (initialValue !== value) {
      updateMyData(value, column, row);
    }
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // ) : (
  return (
    <Box style={{ padding: '16px 0', background }}>
      {inputType ? (
        <input
          style={{ textAlign: 'center', border: 0, background: 'transparent' }}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          type='number'
        />
      ) : (
        <IntervalCell onOpenHeader={onEditDist} val={value as string} />
      )}
    </Box>
  );
};

const defaultPropGetter = () => ({});

const defaultColumn = {
  Cell: EditableCell,
};
function Table({
  columns,
  data,
  updateMyData,
  skipPageReset,
  onEditDist,
  getCellProps = defaultPropGetter,
}: TableProps) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    defaultColumn,
    // use the skipPageReset option to disable page resetting temporarily
    autoResetPage: !skipPageReset,
    updateMyData,
  } as TableOptionsProps);

  // Render the UI for your table
  return (
    <MaUTable {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <StyledTableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <StyledTableCell {...column.getHeaderProps()}>{column.render('Header')}</StyledTableCell>
            ))}
          </StyledTableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <StyledTableRow {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <StyledTableCell {...cell.getCellProps([getCellProps(cell)])}>
                  {cell.render('Cell', {
                    background: cell.column.id === 'intervalDist' ? '#444' : 'transparent',
                    inputType: cell.column.id !== 'intervalDist',
                    onEditDist: () => onEditDist && onEditDist({ dim: 'dist', idx: cell.row.index }),
                  })}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          );
        })}
      </TableBody>
    </MaUTable>
  );
}

export default Table;
