import { useState } from 'react';
import Table from '../components/Table';
import DeleteIcon from '@material-ui/icons/Delete';
import { getBackground } from '../utils';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Paper,
  TextField,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Interval, Pret } from '../utils/types';
import Layout from '../components/Layout';
import useComponenta from '../hooks/useComponenta';
import SectionHeader from '../components/SectionHeader';
import IntervalCell from '../components/IntervalCell';

interface ColumnsProps {
  title: string;
  intervale: Interval[];
  onOpenHeader: Function;
}

const createColumns = ({ intervale, onOpenHeader, title }: ColumnsProps) => [
  {
    Header: title,
    columns: intervale.map(({ id, left, right }, idx) => ({
      Header: <IntervalCell onOpenHeader={() => onOpenHeader(idx)} val={`${left}mm - ${right}mm`} />,
      accessor: id.toString(),
    })),
  },
];

const Panou = () => {
  const [skipPageReset, setSkipPageReset] = useState(false);
  const montanti = useComponenta('MM');
  const profile = useComponenta('PO');

  const updateMyData = (tipCuloareId: number, componentaTip: string) => async (val: number, column: { id: string }) => {
    setSkipPageReset(true);
    if (componentaTip === 'MM') {
      montanti.updatePret({
        tipCuloareId,
        limitaInaltimeId: parseInt(column.id),
        limitaLatimeId: montanti.state.latime[0].id,
        limitaDistId: montanti.state.dist[0].id,
        val,
      });
    } else {
      profile.updatePret({
        tipCuloareId,
        limitaInaltimeId: profile.state.inaltime[0].id,
        limitaLatimeId: parseInt(column.id),
        limitaDistId: profile.state.dist[0].id,
        val,
      });
    }
  };

  return (
    <Layout title={'Panou'}>
      <Box p={3}>
        <SectionHeader
          title='Configurare'
          actions={[
            { name: 'limita inaltime montanti', handler: () => montanti.createLimita('inaltime') },
            { name: 'limita latime profile', handler: () => profile.createLimita('latime') },
          ]}
        />

        {(montanti.loading || profile.loading) && (
          <Box my={3} display='flex' justifyContent='center'>
            <CircularProgress />
          </Box>
        )}

        {!(montanti.loading || profile.loading) &&
          montanti.tipuriCuloare.map((tipCuloare, idx) => (
            <Paper key={tipCuloare.id} style={getBackground(idx)}>
              <Box pt={2} display='flex' justifyContent='center'>
                <Typography variant='h5'>Culoare {tipCuloare.nume}</Typography>
              </Box>
              <Table
                updateMyData={updateMyData(tipCuloare.id, 'MM')}
                skipPageReset={skipPageReset}
                columns={createColumns({
                  intervale: montanti.state.inaltime,
                  onOpenHeader: (idx: number) => montanti.openEdit({ idx, dim: 'inaltime' }),
                  title: 'MONTANTI MARGINE MM',
                })}
                getCellProps={(cellInfo: any) => {
                  const pret: Pret = montanti.preturi.find(
                    (pret) =>
                      pret.limitaInaltimeId === parseInt(cellInfo.column.id) &&
                      pret.limitaLatimeId === montanti.state.latime[0].id &&
                      pret.limitaDistId === montanti.state.dist[0].id &&
                      pret.tipCuloareId === tipCuloare.id
                  )!;
                  return { style: { background: pret.val === pret.temp ? 'transparent' : '#fff00040' } };
                }}
                data={[
                  montanti.state.inaltime.reduce(
                    (data, intervalInaltime) =>
                      Object.assign(data, {
                        [intervalInaltime.id.toString()]: montanti.preturi.find(
                          (pret) =>
                            pret.limitaInaltimeId === intervalInaltime.id &&
                            pret.limitaLatimeId === montanti.state.latime[0].id &&
                            pret.limitaDistId === montanti.state.dist[0].id &&
                            pret.tipCuloareId === tipCuloare.id
                        )!.temp,
                      }),
                    {}
                  ),
                ]}
              />
              <Box my={2}>
                <Table
                  updateMyData={updateMyData(tipCuloare.id, 'PO')}
                  skipPageReset={skipPageReset}
                  columns={createColumns({
                    intervale: profile.state.latime,
                    onOpenHeader: (idx: number) => profile.openEdit({ idx, dim: 'latime' }),
                    title: 'PROFILE ORIZONTALE PO',
                  })}
                  getCellProps={(cellInfo: any) => {
                    const pret: Pret = profile.preturi.find(
                      (pret) =>
                        pret.limitaInaltimeId === profile.state.inaltime[0].id &&
                        pret.limitaLatimeId === parseInt(cellInfo.column.id) &&
                        pret.limitaDistId === profile.state.dist[0].id &&
                        pret.tipCuloareId === tipCuloare.id
                    )!;
                    console.log(
                      { pret, preturi: profile.preturi },
                      profile.state.latime[0].id,
                      parseInt(cellInfo.column.id),
                      profile.state.dist[0].id
                    );
                    return { style: { background: pret.val === pret.temp ? 'transparent' : '#fff00040' } };
                  }}
                  data={[
                    profile.state.latime.reduce(
                      (data, intervalLatime) =>
                        Object.assign(data, {
                          [intervalLatime.id.toString()]: profile.preturi.find(
                            (pret) =>
                              pret.limitaInaltimeId === profile.state.inaltime[0].id &&
                              pret.limitaLatimeId === intervalLatime.id &&
                              pret.limitaDistId === profile.state.dist[0].id &&
                              pret.tipCuloareId === tipCuloare.id
                          )!.val,
                        }),
                      {}
                    ),
                  ]}
                />
              </Box>
            </Paper>
          ))}

        {[montanti, profile].map((componenta) => (
          <Dialog open={!!componenta.state.id} onClose={componenta.endEdit}>
            <DialogTitle>Adauga interval</DialogTitle>
            <DialogContent>
              <TextField
                margin='dense'
                id='min'
                label='Min'
                type='number'
                fullWidth
                value={componenta.state.left}
                onChange={(e) => componenta.changeLeft(parseInt(e.target.value))}
              />
              <TextField
                margin='dense'
                id='max'
                label='Max'
                type='number'
                fullWidth
                value={componenta.state.right}
                onChange={(e) => componenta.changeRight(parseInt(e.target.value))}
              />
              {((componenta.state.dim === 'inaltime' && componenta.state.inaltime.length > 1) ||
                (componenta.state.dim === 'latime' && componenta.state.latime.length > 1)) && (
                <IconButton onClick={componenta.deleteInterval}>
                  <DeleteIcon color='error' />
                </IconButton>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={componenta.endEdit} color='primary'>
                Anuleaza
              </Button>
              <Button onClick={componenta.editInterval} variant='contained' color='primary'>
                Salveaza
              </Button>
            </DialogActions>
          </Dialog>
        ))}
      </Box>
    </Layout>
  );
};

export default Panou;
