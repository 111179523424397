import React, { ReactNode, useState } from 'react';
import Drawer from './Drawer';
import SaveIcon from '@material-ui/icons/Save';
import { Fab, withStyles } from '@material-ui/core';
import { useConfigContext } from '../hooks/ConfigContext';

interface LayoutProps {
  title: string;
  children: ReactNode;
}

const StyledFab = withStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
}))(Fab);

const Layout: React.FC<LayoutProps> = ({ title, children }) => {
  const { instance } = useConfigContext();
  const [loading, setLoading] = useState(false);

  const save = async () => {
    setLoading(true);
    await instance.patch('/preturi/save');
    window.location.reload();
  };

  if (loading) return <h1>Loading...</h1>;

  return (
    <>
      <Drawer title={title} />
      {children}
      <StyledFab color='primary' onClick={save}>
        <SaveIcon />
      </StyledFab>
    </>
  );
};

export default Layout;
