import { useEffect, useReducer } from 'react';
import {
  Box,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Paper,
  Grid,
} from '@material-ui/core';
import PaletteIcon from '@material-ui/icons/Palette';
import DeleteIcon from '@material-ui/icons/Delete';
import SectionHeader from '../components/SectionHeader';
import Layout from '../components/Layout';
import { useConfigContext } from '../hooks/ConfigContext';

interface TipCuloare {
  nume: string;
  id: number;
}

interface Culoare {
  nume: string;
  id: number;
  hex: string;
  tipCuloareId: number;
}

interface CuloareReducerState {
  tipuriCuloare: TipCuloare[];
  culori: Culoare[];
  addingCuloare: number;
  addingTipCuloare: boolean;
  editingTipCuloare: number;
  numeCuloare: string;
  numeTipCuloare: string;
  hexCuloare: string;
}

interface CuloareReducerAction {
  type: 'ADD' | 'END_EDIT';
  payload?: Object;
}

const culoareReducer = (state: CuloareReducerState, action: CuloareReducerAction) => {
  switch (action.type) {
    case 'ADD':
      return Object.assign({}, state, action.payload);
    case 'END_EDIT':
      return {
        ...state,
        addingCuloare: 0,
        addingTipCuloare: false,
        editingTipCuloare: 0,
        numeCuloare: '',
        numeTipCuloare: '',
        hexCuloare: '',
      };
    default:
      return state;
  }
};

const General = () => {
  const { instance } = useConfigContext();
  const [culoareState, dispatchCuloare] = useReducer(culoareReducer, {
    tipuriCuloare: [],
    culori: [],
    addingCuloare: 0,
    addingTipCuloare: false,
    editingTipCuloare: 0,
    numeCuloare: '',
    numeTipCuloare: '',
    hexCuloare: '',
  });

  const fetchCulori = async () => {
    const { data: tipuriCuloare } = await instance.get('/tipuri_culoare');
    const { data: culori } = await instance.get('/culori');
    dispatchCuloare({ type: 'ADD', payload: { tipuriCuloare, culori } });
  };

  useEffect(() => {
    (async () => {
      await fetchCulori();
    })();
  }, []);

  // const handleEditTipCuloare = async () => {
  //   if (culoareState.addingTipCuloare) {
  //     await instance.post('/tipuri_culoare', { nume: culoareState.numeTipCuloare });
  //   } else {
  //     await instance.patch('/tipuri_culoare', {
  //       id: culoareState.editingTipCuloare,
  //       nume: culoareState.numeTipCuloare,
  //     });
  //   }

  //   await fetchCulori();
  //   dispatchCuloare({ type: 'END_EDIT' });
  // };

  // const handleDeleteTipCuloare = async (id: number) => {
  //   await instance.delete('/tipuri_culoare', { data: { id } });
  //   await fetchCulori();
  //   dispatchCuloare({ type: 'END_EDIT' });
  // };

  const handleAdaugaCuloare = async () => {
    // Verify for valid hex code #ABC123
    if (!/^#[0-9A-F]{6}$/i.test(culoareState.hexCuloare)) return;
    await instance.post('/culori', {
      nume: culoareState.numeCuloare,
      hex: culoareState.hexCuloare,
      tipCuloareId: culoareState.addingCuloare,
    });

    await fetchCulori();
    dispatchCuloare({ type: 'END_EDIT' });
  };

  const handleDeleteCuloare = async (id: number) => {
    await instance.delete('/culori', { data: { id } });
    fetchCulori();
  };

  return (
    <Layout title='Culoare'>
      <Box p={3}>
        <SectionHeader
          title='Tipuri de culoare'
          actions={[]}
          // {
          //   name: 'tip culoare',
          //   handler: () => dispatchCuloare({ type: 'ADD', payload: { addingTipCuloare: true } }),
          // },
        />

        <Grid container spacing={2}>
          {culoareState.tipuriCuloare.map((tipCuloare) => (
            <Grid key={tipCuloare.id} item xs={12} sm={6} md={4}>
              <Paper>
                <Box py={3} display='flex' flexDirection='column' alignItems='center'>
                  <Typography variant='h4'>{tipCuloare.nume}</Typography>
                  <Box my={3} width='100%'>
                    {culoareState.culori.map(
                      (culoare) =>
                        culoare.tipCuloareId === tipCuloare.id && (
                          <Box
                            key={culoare.id}
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            mb={1}
                            mx={10}
                            style={{ background: culoare.hex }}
                            px={2}
                          >
                            <Box px={1} display='flex' alignItems='center' style={{ background: '#fff' }}>
                              <PaletteIcon />
                              <Box ml={1}>
                                <Typography variant='h6'>{culoare.nume}</Typography>
                              </Box>
                            </Box>
                            <IconButton onClick={() => handleDeleteCuloare(culoare.id)}>
                              <DeleteIcon color='error' />
                            </IconButton>
                          </Box>
                        )
                    )}
                  </Box>
                  <Button
                    variant='contained'
                    color='default'
                    onClick={() => dispatchCuloare({ type: 'ADD', payload: { addingCuloare: tipCuloare.id } })}
                  >
                    Adauga culoare
                  </Button>
                </Box>
              </Paper>
            </Grid>
            // <Accordion key={tipCuloare.id}>
            //   <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            //     <Box display='flex' alignItems='center'>
            //       <Typography variant='h4'>{tipCuloare.nume}</Typography>
            //       <IconButton
            //         color='default'
            //         onClick={() =>
            //           dispatchCuloare({
            //             type: 'ADD',
            //             payload: { numeTipCuloare: tipCuloare.nume, editingTipCuloare: tipCuloare.id },
            //           })
            //         }
            //       >
            //         <EditIcon />
            //       </IconButton>
            //     </Box>
            //   </AccordionSummary>
            //   <AccordionDetails>
            //     <Box>
            //       {culoareState.culori.map(
            //         (culoare) =>
            //           culoare.tipCuloareId === tipCuloare.id && (
            //             <Box key={culoare.id} display='flex' justifyContent='space-between' alignItems='center' mb={1}>
            //               <Typography variant='h6'>
            //                 <PaletteIcon style={{ color: culoare.hex }} />
            //                 {culoare.nume}
            //               </Typography>
            //               <IconButton onClick={() => handleDeleteCuloare(culoare.id)}>
            //                 <DeleteIcon color='error' />
            //               </IconButton>
            //             </Box>
            //           )
            //       )}
            //       <Button
            //         variant='contained'
            //         color='default'
            //         onClick={() => dispatchCuloare({ type: 'ADD', payload: { addingCuloare: tipCuloare.id } })}
            //       >
            //         Adauga culoare
            //       </Button>
            //     </Box>
            //   </AccordionDetails>
            // </Accordion>
          ))}
        </Grid>

        {/* <Dialog
          open={culoareState.addingTipCuloare || !!culoareState.editingTipCuloare}
          onClose={() => dispatchCuloare({ type: 'END_EDIT' })}
          >
          <DialogTitle>{culoareState.addingTipCuloare ? 'Adauga tip culoare' : 'Editeaza culoare'}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin='dense'
              id='tip_nume'
              label='Nume'
              fullWidth
              value={culoareState.numeTipCuloare}
              onChange={(e) => dispatchCuloare({ type: 'ADD', payload: { numeTipCuloare: e.target.value } })}
            />

            {!!culoareState.editingTipCuloare && (
              <IconButton onClick={() => handleDeleteTipCuloare(culoareState.editingTipCuloare)}>
                <DeleteIcon color='error' />
              </IconButton>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => dispatchCuloare({ type: 'END_EDIT' })} color='primary'>
              Anuleaza
            </Button>
            <Button onClick={handleEditTipCuloare} variant='contained' color='primary'>
              Adauga
            </Button>
          </DialogActions>
        </Dialog> */}

        <Dialog open={!!culoareState.addingCuloare} onClose={() => dispatchCuloare({ type: 'END_EDIT' })}>
          <DialogTitle>
            Adauga culoare tip "{culoareState.tipuriCuloare.find(({ id }) => id === culoareState.addingCuloare)?.nume}"
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin='dense'
              id='name'
              label='Nume'
              fullWidth
              value={culoareState.numeCuloare}
              onChange={(e) => dispatchCuloare({ type: 'ADD', payload: { numeCuloare: e.target.value } })}
            />
            <TextField
              margin='dense'
              id='hex'
              label='Hex'
              fullWidth
              value={culoareState.hexCuloare}
              onChange={(e) => dispatchCuloare({ type: 'ADD', payload: { hexCuloare: e.target.value } })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => dispatchCuloare({ type: 'END_EDIT' })} color='default'>
              Anuleaza
            </Button>
            <Button onClick={handleAdaugaCuloare} variant='contained' color='primary'>
              Adauga
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Layout>
  );
};

export default General;
