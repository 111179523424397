import { Link } from '@material-ui/core';
import { MouseEventHandler } from 'react';

interface IntervalCellProps {
  onOpenHeader: MouseEventHandler;
  val: string;
}

const IntervalCell: React.FC<IntervalCellProps> = ({ val, onOpenHeader }: IntervalCellProps) => {
  return (
    <Link color='secondary' onClick={onOpenHeader} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
      {val}
    </Link>
  );
};

export default IntervalCell;
