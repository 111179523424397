import { useEffect, useReducer } from 'react';
import { useState } from 'react';
import { createIntervals, sortByAttribute } from '../utils';
import { Componenta, Dimension, Limita, OpenEditPayload, Pret, PretData, TipCuloare } from '../utils/types';
import { useConfigContext } from './ConfigContext';
import intervaleReducer from './intervaleReducer';

const useComponenta = (tip: string) => {
  const { instance } = useConfigContext();
  const [componenta, setComponenta] = useState<Componenta>({});
  const [tipuriCuloare, setTipuriCuloare] = useState<TipCuloare[]>([]);
  const [preturi, setPreturi] = useState<Pret[]>([]);
  const [loading, setLoading] = useState(false);

  const [state, dispatch] = useReducer(intervaleReducer, {
    id: 0,
    dim: '',
    left: 0,
    right: 0,
    idx: 0,
    inaltime: [],
    latime: [],
    dist: [],
  });

  useEffect(() => {
    (async () => {
      await fetchComponenta();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (componenta.minInaltime !== undefined) await fetchPreturi();
    })();
  }, [componenta.minInaltime, componenta.minLatime, componenta.minDist]);

  const fetchComponenta = async () => {
    setLoading(true);
    try {
      const { data }: { data: Componenta } = await instance.get(`/componente/${tip}`);
      setComponenta({
        minInaltime: data.minInaltime,
        minLatime: data.minLatime,
        minDist: data.minDist,
      });
    } catch (err: any) {
      console.error(err.message);
    }
  };

  const fetchPreturi = async () => {
    await fetchComponenta();

    const { data }: PretData = await instance.get(`/preturi/${tip}`);

    const newLimiteInaltime: Limita[] = [];
    const newLimiteLatime: Limita[] = [];
    const newLimiteDist: Limita[] = [];
    const newTipuriCuloare: TipCuloare[] = [];
    const newPreturi: Pret[] = [];

    data.forEach((item) => {
      if (!newLimiteInaltime.find((i) => i.id === item.limitaInaltimeId))
        newLimiteInaltime.push({ id: item.limitaInaltimeId, val: item.limitaInaltimeVal });
      if (!newLimiteLatime.find((i) => i.id === item.limitaLatimeId))
        newLimiteLatime.push({ id: item.limitaLatimeId, val: item.limitaLatimeVal });
      if (!newLimiteDist.find((i) => i.id === item.limitaDistId))
        newLimiteDist.push({ id: item.limitaDistId, val: item.limitaDistVal });
      if (!newTipuriCuloare.find((i) => i.id === item.tipCuloareId))
        newTipuriCuloare.push({ id: item.tipCuloareId, nume: item.tipCuloareNume });

      newPreturi.push({
        val: item.val,
        temp: item.temp,
        limitaInaltimeId: item.limitaInaltimeId,
        limitaLatimeId: item.limitaLatimeId,
        limitaDistId: item.limitaDistId,
        tipCuloareId: item.tipCuloareId,
      });
    });

    dispatch({
      type: 'ADD_INTERVALS',
      payload: {
        inaltime: createIntervals(newLimiteInaltime, componenta.minInaltime!),
        latime: createIntervals(newLimiteLatime, componenta.minLatime!),
        dist: createIntervals(newLimiteDist, componenta.minDist!),
      },
    });

    setTipuriCuloare(sortByAttribute(newTipuriCuloare, 'id'));
    setPreturi(newPreturi);
    setLoading(false);
  };

  const updatePret = async (newPret: Pret) => {
    const { data } = await instance.patch(`/preturi`, newPret);

    console.log({ data });

    setPreturi((old) =>
      old.map((pret) =>
        pret.limitaInaltimeId === newPret.limitaInaltimeId &&
        pret.limitaLatimeId === newPret.limitaLatimeId &&
        pret.limitaDistId === newPret.limitaDistId &&
        pret.tipCuloareId === newPret.tipCuloareId
          ? { ...pret, temp: data.val }
          : pret
      )
    );
  };

  const createLimita = async (dim: Dimension) => {
    setLoading(true);
    const intervale = state[dim];
    const val = (intervale.length ? intervale[intervale.length - 1].right : 0) + 20;
    await instance.post(`/limite/${dim}`, { componentaTip: tip, val });
    await fetchComponenta();
    await fetchPreturi();
  };

  const editInterval = async () => {
    setLoading(true);
    let updatedComponenta = false;

    const { id, dim, idx, left, right } = state;

    if (state[dim][idx].left !== left) {
      if (idx === 0) {
        updatedComponenta = true;
        await instance.patch(`/componente`, {
          tip,
          minInaltime: dim === 'inaltime' ? left : componenta.minInaltime,
          minLatime: dim === 'latime' ? left : componenta.minLatime,
          minDist: dim === 'dist' ? left : componenta.minDist,
        });
      } else {
        await instance.patch(`/limite/${state.dim}`, { id: state[dim][idx - 1].id, val: left });
      }
    }

    if (state[state.dim][state.idx].right !== state.right) {
      await instance.patch(`/limite/${state.dim}`, { id, val: right });
    }

    await (updatedComponenta ? fetchComponenta() : fetchPreturi());
    dispatch({ type: 'END_EDIT' });
  };

  const deleteInterval = async () => {
    setLoading(true);
    const { id, dim } = state;
    dispatch({ type: 'END_EDIT' });
    await instance.delete(`/limite/${dim}`, { data: { id } });
    await fetchPreturi();
  };

  return {
    state,
    tipuriCuloare,
    preturi,
    loading,
    createLimita,
    editInterval,
    deleteInterval,
    updatePret,
    openEdit: (payload: OpenEditPayload) => dispatch({ type: 'OPEN_EDIT', payload }),
    changeLeft: (val: number) => dispatch({ type: 'CHANGE_LEFT', payload: { val } }),
    changeRight: (val: number) => dispatch({ type: 'CHANGE_RIGHT', payload: { val } }),
    endEdit: () => dispatch({ type: 'END_EDIT' }),
  };
};

export default useComponenta;
