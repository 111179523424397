import { Box, Button, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

interface Action {
  name: string;
  handler: any;
}

interface SectionHeaderProps {
  title: string;
  actions: Action[];
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, actions }) => {
  return (
    <Box display='flex' alignItems='center' justifyContent='space-between'>
      <Typography variant='h4'>{title}</Typography>
      <Box my={3} display='flex' justifyContent='space-around'>
        {actions.map(({ name, handler }) => (
          <Box key={name} mx={1}>
            <Button variant='outlined' color='default' onClick={handler}>
              <AddIcon />
              Adauga {name}
            </Button>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SectionHeader;
