export const getBackground = (idx: number) => ({
  background: ['#0DFF0015', '#4D270C15'][idx % 3],
  // background: ['#0400FF15', '#FF000015', '#0DFF0015'][idx % 3],
});

export const sortByAttribute = (list: Array<any>, att: any) => list.sort((i, j) => i[att] - j[att]);

export const createIntervals = (limitList: any, min: number) => {
  let leftLimit = min;

  return sortByAttribute(limitList, 'val').map(({ id, val }) => {
    const temp = leftLimit;
    leftLimit = val;

    return { id, left: temp, right: val };
  });
};
