import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { ConfigProvider, useConfigContext } from './hooks/ConfigContext';
import Login from './modules/Login';
import General from './modules/General';
import Poarta from './modules/Poarta';
import Panou from './modules/Panou';
import Register from './modules/Register';
import { useEffect, useState } from 'react';

const Main = () => {
  const { instance, token, updateToken } = useConfigContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const {
        data: { accessToken },
      } = await instance.post('/refresh_token');
      console.log({ accessToken });
      updateToken(accessToken);
      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter>
      <CssBaseline />
      <div>
        <Switch>
          {!token && <Route path='/' component={Login} exact />}
          {!token && <Route path='/register' component={Register} exact />}
          {token && <Route path='/' component={General} exact />}
          {token && <Route path='/paa' component={() => <Poarta tip='PAA' />} exact />}
          {token && <Route path='/pab' component={() => <Poarta tip='PAB' />} exact />}
          {token && <Route path='/pac' component={() => <Poarta tip='PAC' />} exact />}
          {token && <Route path='/pp' component={() => <Poarta tip='PP' />} exact />}
          {token && <Route path='/panou' component={Panou} exact />}
          <Redirect to='/' exact />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

const App = () => (
  <ThemeProvider theme={theme}>
    <ConfigProvider>
      <Main />
    </ConfigProvider>
  </ThemeProvider>
);

export default App;

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto',
          padding: 0,
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#0E7B42',
    },
    secondary: {
      main: '#DBFF4D',
    },
  },
});
