import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Paper,
  TextField,
  Typography,
  IconButton,
  LinearProgress,
  Grid,
  TextareaAutosize,
} from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { MouseEventHandler, useState } from 'react';
import Table from '../components/Table';
import DeleteIcon from '@material-ui/icons/Delete';
import { getBackground } from '../utils';
import Layout from '../components/Layout';
import SectionHeader from '../components/SectionHeader';
import { Interval, Pret } from '../utils/types';
import { Row } from 'react-table';
import useComponenta from '../hooks/useComponenta';
import useOptionale from '../hooks/useOptionale';
import IntervalCell from '../components/IntervalCell';

interface ColumnsProps {
  leftInaltime: number;
  rightInaltime: number;
  intervaleLatime: Interval[];
  onOpenInaltime: MouseEventHandler;
  onOpenLatime: Function;
}

const createColumns = ({
  leftInaltime,
  rightInaltime,
  intervaleLatime,
  onOpenInaltime,
  onOpenLatime,
}: ColumnsProps) => [
  {
    Header: <IntervalCell onOpenHeader={onOpenInaltime} val={`${leftInaltime}mm < H < ${rightInaltime}mm`} />,
    id: 'hcol',
    columns: [{ Header: 'Distanta intre lamele', accessor: 'intervalDist' }],
  },
  {
    Header: 'Latime gol acces auto',
    columns: intervaleLatime.map(({ id, left, right }: Interval, idx: number) => ({
      Header: <IntervalCell onOpenHeader={() => onOpenLatime(idx)} val={`${left}mm - ${right}mm`} />,
      accessor: id.toString(),
    })),
  },
];

interface PoartaProps {
  tip: string;
}

const Poarta = ({ tip }: PoartaProps) => {
  const [skipPageReset, setSkipPageReset] = useState(false);
  const componenta = useComponenta(tip);
  const optionale = useOptionale(tip);

  const updateMyData =
    (tipCuloareId: number, inaltime: number) => async (val: number, column: { id: string }, row: Row) => {
      console.log(column);
      setSkipPageReset(true);

      componenta.updatePret({
        tipCuloareId,
        limitaInaltimeId: inaltime,
        limitaLatimeId: parseInt(column.id),
        limitaDistId: componenta.state.dist![row.index].id,
        val,
      });
    };

  if (componenta.loading)
    return (
      <Layout title={tip}>
        <LinearProgress />
      </Layout>
    );

  return (
    <Layout title={tip}>
      <Box p={3}>
        <SectionHeader
          title='Configurare'
          actions={[
            { name: 'limita inaltime', handler: () => componenta.createLimita('inaltime') },
            { name: 'limita latime', handler: () => componenta.createLimita('latime') },
            { name: 'limita dist', handler: () => componenta.createLimita('dist') },
          ]}
        />

        {componenta.tipuriCuloare.map((tipCuloare, idx) => (
          <Paper key={tipCuloare.id} style={getBackground(idx)}>
            <Box pt={2} display='flex' justifyContent='center'>
              <Typography variant='h5'>Culoare {tipCuloare.nume}</Typography>
            </Box>
            {componenta.state.inaltime.map((intervalInaltime, intervalInaltimeIdx) => (
              <Box key={`inaltime${intervalInaltime.id}`} my={2}>
                <Table
                  updateMyData={updateMyData(tipCuloare.id, intervalInaltime.id)}
                  skipPageReset={skipPageReset}
                  onEditDist={componenta.openEdit}
                  columns={createColumns({
                    leftInaltime: intervalInaltime.left,
                    rightInaltime: intervalInaltime.right,
                    intervaleLatime: componenta.state.latime,
                    onOpenInaltime: () => componenta.openEdit({ dim: 'inaltime', idx: intervalInaltimeIdx }),
                    onOpenLatime: (idx: number) => componenta.openEdit({ dim: 'latime', idx }),
                  })}
                  getCellProps={(cellInfo: any) => {
                    if (cellInfo.column.id === 'intervalDist') {
                      return { style: {} };
                    }
                    const pret: Pret = componenta.preturi.find(
                      (pret) =>
                        pret.limitaInaltimeId === intervalInaltime.id &&
                        pret.limitaLatimeId === parseInt(cellInfo.column.id) &&
                        pret.limitaDistId === componenta.state.dist[cellInfo.row.index].id &&
                        pret.tipCuloareId === tipCuloare.id
                    )!;
                    return { style: { background: pret.val === pret.temp ? 'transparent' : '#fff00040' } };
                  }}
                  data={componenta.state.dist!.map((intervalDist) =>
                    componenta.state.latime.reduce(
                      (data, intervalLatime) => {
                        return Object.assign(data, {
                          [intervalLatime.id.toString()]: componenta.preturi.find(
                            (pret) =>
                              pret.limitaInaltimeId === intervalInaltime.id &&
                              pret.limitaLatimeId === intervalLatime.id &&
                              pret.limitaDistId === intervalDist.id &&
                              pret.tipCuloareId === tipCuloare.id
                          )!.temp,
                        });
                      },
                      { intervalDist: `${intervalDist.left}mm - ${intervalDist.right}mm` }
                    )
                  )}
                />
              </Box>
            ))}
          </Paper>
        ))}

        <SectionHeader title='Optionale' actions={[{ name: 'optionala', handler: optionale.startCreate }]} />

        <Grid container spacing={2}>
          {optionale.state.list.map(({ id, nume, pret, observatii, imageURL }) => (
            <Grid key={id} item xs={12} sm={6} md={4}>
              <Paper>
                <Box py={3} display='flex' flexDirection='column' alignItems='center'>
                  <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography variant='h4'>{nume}</Typography>
                    <Box mx={2}>
                      <Chip label={`${pret} lei`} icon={<LocalOfferIcon />} color='primary' />
                    </Box>
                  </Box>
                  <Box display='flex' alignItems='center' flexDirection='column' mb={1}>
                    <Box mb={2} mx='auto'>
                      <img
                        src={`${process.env.REACT_APP_SOURCE_URL}/images/${imageURL}`}
                        width='256'
                        height='256'
                        alt={nume}
                      />
                    </Box>
                    <Box mb={1} mx={2}>
                      <Typography variant='h5'>Observatii</Typography>
                      <Typography variant='body1' style={{ whiteSpace: 'pre-wrap' }}>
                        {observatii}
                      </Typography>
                    </Box>
                    <Button
                      variant='contained'
                      color='default'
                      onClick={() => optionale.openEdit({ id, nume, pret, observatii })}
                    >
                      Editeaza
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>

        {/* <Box my={2}>
          {optionale.state.list.map(({ id, nume, pret, observatii, imageURL }) => (
            <Accordion key={id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Typography variant='h4'>{nume}</Typography>
                  <Box mx={2}>
                    <Chip label={`${pret} lei`} icon={<LocalOfferIcon />} color='primary' />
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box display='flex' flexDirection='column' mb={1}>
                  <Box mb={1}>
                    <Typography variant='h5'>Observatii</Typography>
                    <Typography variant='body1'>{observatii}</Typography>
                  </Box>
                  <Box mb={2}>
                    <img src={`http://localhost:4000/images/${imageURL}`} width='128' height='128' />
                  </Box>
                  <Button
                    variant='contained'
                    color='default'
                    onClick={() => optionale.openEdit({ id, nume, pret, observatii })}
                  >
                    Editeaza
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box> */}

        <Dialog open={!!componenta.state.id} onClose={componenta.endEdit}>
          <DialogTitle>Adauga interval</DialogTitle>
          <DialogContent>
            <TextField
              margin='dense'
              id='min'
              label='Min'
              type='number'
              fullWidth
              value={componenta.state.left}
              onChange={(e) => componenta.changeLeft(parseInt(e.target.value))}
            />
            <TextField
              margin='dense'
              id='max'
              label='Max'
              type='number'
              fullWidth
              value={componenta.state.right}
              onChange={(e) => componenta.changeRight(parseInt(e.target.value))}
            />
            {componenta.state[componenta.state.dim]?.length > 1 && (
              <IconButton onClick={componenta.deleteInterval}>
                <DeleteIcon color='error' />
              </IconButton>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={componenta.endEdit} color='primary'>
              Anuleaza
            </Button>
            <Button onClick={componenta.editInterval} variant='contained' color='primary'>
              Salveaza
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={!!optionale.state.id || optionale.state.creating} onClose={optionale.endEdit}>
          <DialogTitle>Adauga optionala</DialogTitle>
          <DialogContent>
            <Box>
              <TextField
                margin='dense'
                id='nume'
                label='Nume'
                fullWidth
                value={optionale.state.nume}
                onChange={(e) => optionale.editNumeOptionala(e.target.value)}
              />
              <TextField
                margin='dense'
                id='pret'
                label='Pret'
                type='number'
                fullWidth
                value={optionale.state.pret}
                onChange={(e) => optionale.editPretOptionala(parseInt(e.target.value))}
              />
              <TextareaAutosize
                id='obs'
                style={{ minWidth: '100%', resize: 'vertical' }}
                value={optionale.state.observatii}
                onChange={(e) => optionale.editObservatiiOptionala(e.target.value)}
              />
              {optionale.state.creating ? (
                <TextField
                  type='file'
                  margin='dense'
                  id='obs'
                  label='Observatii'
                  fullWidth
                  onChange={(e) => optionale.editImagineOptionala((e.target as any).files[0])}
                />
              ) : (
                <IconButton onClick={optionale.deleteOptionala}>
                  <DeleteIcon color='error' />
                </IconButton>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={optionale.endEdit} color='primary'>
              Anuleaza
            </Button>
            <Button onClick={optionale.editOptionala} variant='contained' color='primary'>
              Salveaza
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Layout>
  );
};

export default Poarta;
