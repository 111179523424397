import { Optionala } from '../utils/types';

export interface State {
  id: number;
  nume: string;
  pret: number;
  observatii: string;
  // imagine: string;
  imagine: Blob | null;
  list: Optionala[];
  creating: boolean;
}

type Action =
  | {
      type: 'ADD';
      payload: {
        id?: number;
        nume?: string;
        pret?: number;
        observatii?: string;
        // imagine?: string;
        imagine?: Blob | null;
        list?: Optionala[];
        creating?: boolean;
      };
    }
  | { type: 'START_CREATE' }
  | { type: 'EDIT_OPTIONALA' }
  | { type: 'DELETE_OPTIONALA' }
  | { type: 'END_EDIT' };

export const optionaleReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'ADD':
      return Object.assign({}, state, action.payload);
    case 'START_CREATE':
      return { ...state, creating: true };
    case 'EDIT_OPTIONALA':
      return {
        ...state,
        list: state.list.map((optionala) =>
          optionala.id === state.id
            ? {
                ...optionala,
                id: state.id,
                nume: state.nume,
                pret: state.pret,
                observatii: state.observatii,
                // imageURL: state.imagine,
              }
            : optionala
        ),
      };
    case 'DELETE_OPTIONALA':
      return {
        ...state,
        list: state.list.filter(({ id }) => id !== state.id),
      };
    case 'END_EDIT':
      return { ...state, id: 0, nume: '', pret: 0, observatii: '', imagine: null, creating: false };
    default:
      return state;
  }
};
