import { Box, Button, Container, Grid, Snackbar, TextField } from '@material-ui/core';
import { FormEvent, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useConfigContext } from '../hooks/ConfigContext';

const Register: React.FC<RouteComponentProps> = ({ history }) => {
  const { instance } = useConfigContext();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (email.trim().length === 0 || password.trim().length === 0) {
      return;
    }

    const response = await instance.post('/register', { email, password });
    console.log({ response });
    history.push('/');
  };

  return (
    <Grid container component='main'>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message='User sau parola gresita'
        autoHideDuration={3000}
      />

      <Container component='main' maxWidth='sm'>
        <Box m={2}>
          <img className='logo-image' src='/logo.png' alt='logo' width='20%' />
        </Box>

        <div>
          <form onSubmit={handleSubmit}>
            <Box m={2}>
              <TextField
                variant='outlined'
                value={email}
                label='Email'
                fullWidth
                placeholder='Email'
                onChange={(e) => setEmail(e.target.value)}
              />
            </Box>
            <Box m={2}>
              <TextField
                variant='outlined'
                label='Parola'
                fullWidth
                type='password'
                value={password}
                placeholder='Email'
                onChange={(e) => setPassword(e.target.value)}
              />
            </Box>
            <Box m={2} display='flex'>
              <Button variant='contained' color='primary' type='submit' size='large'>
                Inregistrare
              </Button>
            </Box>
          </form>
        </div>
      </Container>
    </Grid>
  );
};

export default Register;
