import { useEffect, useReducer } from 'react';
import { useConfigContext } from './ConfigContext';
import { optionaleReducer } from './optionaleReducer';

const useOptionale = (tip: string) => {
  const { instance } = useConfigContext();
  const [state, dispatch] = useReducer(optionaleReducer, {
    id: 0,
    nume: '',
    pret: 0,
    observatii: '',
    imagine: null,
    list: [],
    creating: false,
  });

  useEffect(() => {
    (async () => {
      await fetchOptionale();
    })();
  }, []);

  const fetchOptionale = async () => {
    const { data } = await instance.get(`/optionale/${tip}`);
    dispatch({ type: 'ADD', payload: { list: data } });
    endEdit();
  };

  const editOptionala = async () => {
    const { id, nume, pret, observatii, imagine, creating } = state;

    if (!pret || !nume) {
      return;
    }

    if (creating) {
      const formData = new FormData();

      formData.append('nume', nume);
      formData.append('pret', pret.toString());
      formData.append('observatii', observatii);
      formData.append('imagine', imagine!);
      formData.append('componentaTip', tip);

      // await instance.post(`/optionale`, { nume, pret, observatii, imageURL: imagine, componentaTip: tip });
      await instance.post(`/optionale`, formData);
      await fetchOptionale();
    } else {
      const { status } = await instance.patch(`/optionale`, { id, nume, pret, observatii, imageURL: imagine });
      if (status === 200) {
        dispatch({ type: 'EDIT_OPTIONALA' });
      }
    }
    endEdit();
  };

  const deleteOptionala = async () => {
    const { status } = await instance.delete(`/optionale`, { data: { id: state.id } });

    if (status === 200) {
      dispatch({ type: 'DELETE_OPTIONALA' });
    }
    endEdit();
  };

  const endEdit = () => {
    dispatch({ type: 'END_EDIT' });
  };

  return {
    state,
    editOptionala,
    deleteOptionala,
    editNumeOptionala: (nume: string) => dispatch({ type: 'ADD', payload: { nume } }),
    editPretOptionala: (pret: number) => dispatch({ type: 'ADD', payload: { pret } }),
    editObservatiiOptionala: (observatii: string) => dispatch({ type: 'ADD', payload: { observatii } }),
    editImagineOptionala: (imagine: Blob) => dispatch({ type: 'ADD', payload: { imagine } }),
    startCreate: () => dispatch({ type: 'START_CREATE' }),
    openEdit: (payload: { id: number; nume: string; pret: number; observatii: string; imagine?: Blob }) =>
      dispatch({ type: 'ADD', payload }),
    endEdit,
  };
};

export default useOptionale;
