import axios, { AxiosInstance } from 'axios';
import { createContext, useContext, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';

interface ConfigContextProps {
  token: string;
  updateToken: Function;
  instance: AxiosInstance;
}

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_SOURCE_URL}/api`,
  withCredentials: true,
});
const refreshTokenInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SOURCE_URL}/api/refresh_token`,
  withCredentials: true,
});

let instanceInterceptor = -1;

const ConfigContext = createContext<ConfigContextProps>({ token: '', updateToken: () => {}, instance });
export const useConfigContext = () => useContext(ConfigContext);

interface ConfigProviderProps {
  children: any;
}

export function ConfigProvider({ children }: ConfigProviderProps) {
  const [token, setToken] = useState('');

  const isTokenValid = () => {
    try {
      if (!token) {
        throw new Error('no token');
      }

      const decoded: any = jwtDecode(token);
      return Date.now() < decoded.exp * 1000;
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    if (!token) {
      return;
    }

    if (instanceInterceptor !== -1) {
      instance.interceptors.request.eject(instanceInterceptor);
    }

    instanceInterceptor = instance.interceptors.request.use(async (config) => {
      if (config.url === '/login' || isTokenValid() || config.method === 'get') {
        return config;
      }

      const response = await refreshTokenInstance.post('/');
      if (response && response.data) {
        instance.defaults.headers.common['authorization'] = `bearer ${response.data.accessToken}`;
        setToken(response.data.accessToken);
      }

      return config;
    });
  }, [token]);

  const updateToken = (newToken: string) => {
    instance.defaults.headers.common['authorization'] = newToken ? `bearer ${newToken}` : '';
    setToken(newToken);
  };

  return <ConfigContext.Provider value={{ token, updateToken, instance }}>{children}</ConfigContext.Provider>;
}
