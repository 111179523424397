import { AddIntervalPayload, Interval, OpenEditPayload } from '../utils/types';

interface State {
  id: number;
  dim: 'inaltime' | 'latime' | 'dist' | '';
  idx: number;
  left: number;
  right: number;
  inaltime: Interval[];
  latime: Interval[];
  dist: Interval[];
}

type Action =
  | {
      type: 'ADD_INTERVALS';
      payload: AddIntervalPayload;
    }
  | {
      type: 'CHANGE_RIGHT';
      payload: { val: number };
    }
  | {
      type: 'CHANGE_LEFT';
      payload: { val: number };
    }
  | {
      type: 'OPEN_EDIT';
      payload: OpenEditPayload;
    }
  | {
      type: 'END_EDIT';
    };

const intervaleReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'ADD_INTERVALS':
      return Object.assign({}, state, action.payload);
    case 'CHANGE_RIGHT':
      return Object.assign({}, state, { right: action.payload.val });
    case 'CHANGE_LEFT':
      return Object.assign({}, state, { left: action.payload.val });
    case 'OPEN_EDIT':
      const { dim, idx } = action.payload;
      return {
        ...state,
        id: state[dim][idx].id,
        left: state[dim][idx].left,
        right: state[dim][idx].right,
        dim,
        idx,
      };
    case 'END_EDIT':
      return { ...state, dim: '', id: 0 };
    default:
      return state;
  }
};

export default intervaleReducer;
