import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  IconButton,
  Typography,
  ListItemText,
  ListItem,
  ListItemIcon,
  Divider,
  List,
  Drawer as MUIDrawer,
  Button,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useConfigContext } from '../hooks/ConfigContext';
import FileSaver from 'file-saver';

interface DrawerProps {
  title: string;
}

interface LinkProps {
  to: string;
  children: any;
}

const ListItemLink = ({ children, ...props }: LinkProps) => (
  <ListItem button component={Link} {...props}>
    {children}
  </ListItem>
);

const Drawer: React.FC<DrawerProps> = ({ title }) => {
  const { instance, updateToken } = useConfigContext();
  const [open, setOpen] = useState<boolean>(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpen(open);
  };

  const logout = async () => {
    await instance.post('/logout');
    updateToken('');
  };

  const exportData = async () => {
    const { data } = await instance.get('/export');
    const csv = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    FileSaver.saveAs(csv, 'preturi.csv');
  };

  return (
    <>
      <Box p={0} display='flex' alignItems='center' justifyContent='space-between' borderBottom={1}>
        <IconButton onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>
        <Box>
          <Typography variant='h5'>{title}</Typography>
        </Box>
        <Button onClick={logout}>Deconectare</Button>
      </Box>
      <MUIDrawer open={open} onClose={toggleDrawer(false)}>
        <Box onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)} width={250}>
          <List>
            <ListItemLink to='/culoare'>
              <ListItemIcon>
                <ColorLensIcon />
              </ListItemIcon>
              <ListItemText primary={'Culori'} />
            </ListItemLink>
          </List>
          <Divider />
          <List>
            <ListItemLink to='/paa'>
              <ListItemIcon>
                <LocalOfferIcon />
              </ListItemIcon>
              <ListItemText primary={'PAA'} />
            </ListItemLink>
            <ListItemLink to='/pab'>
              <ListItemIcon>
                <LocalOfferIcon />
              </ListItemIcon>
              <ListItemText primary={'PAB'} />
            </ListItemLink>
            <ListItemLink to='/pac'>
              <ListItemIcon>
                <LocalOfferIcon />
              </ListItemIcon>
              <ListItemText primary={'PAC'} />
            </ListItemLink>
            <ListItemLink to='/pp'>
              <ListItemIcon>
                <LocalOfferIcon />
              </ListItemIcon>
              <ListItemText primary={'PP'} />
            </ListItemLink>
            <ListItemLink to='/panou'>
              <ListItemIcon>
                <LocalOfferIcon />
              </ListItemIcon>
              <ListItemText primary={'Panou'} />
            </ListItemLink>
          </List>
          <Divider />
          <List>
            <ListItem onClick={exportData}>
              <ListItemIcon>
                <GetAppIcon />
              </ListItemIcon>
              <ListItemText primary={'Exporteaza'} />
            </ListItem>
          </List>
        </Box>
      </MUIDrawer>
    </>
  );
};

export default Drawer;
